.btn-primary{
  color: #fff;
  background-color: $fourth-theme-color;
  border-color: $fourth-theme-color;
}

.btn-primary:hover {
  color: #fff;
  background-color: $third-theme-color;
  border-color: $third-theme-color;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem $third-theme-color;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: $fourth-theme-color;
  border-color: $fourth-theme-color;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $rouge-lp;
  border-color: $rouge-lp-hover;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $rouge-lp;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $rouge-lp;
}

.text-gris-sous-menu{
  color: $gris-fonce !important;
}
